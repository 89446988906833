import React from 'react';
import { Layout, Seo } from 'components';
import { Col, Row } from "react-grid-system";
import SideNav from '../codes/components/side-nav';


import './site-map.scss';

const SiteMap = () => {

    return (
        <Layout indication="patient" className="site-map">
            <Seo pageTitle="Site Map" pageDescription="Review the site map for the Butrans transdermal system CIII website. See Full Prescribing Info, Safety Info & Boxed Warning."/>
            <div className="body-content-container">
            <SideNav pageId="site-map-patient" />

                <div className="body-content gutter-all">
                    <Row>
                        <Col xs={12}>
                            <h1>Site Map</h1>
                            <ul class="sitemap-list">
                                    <li>
                                        <p><a href="/">Home</a></p>
                                    </li>
                                    <li>
                                        <p><a href="/resources/">Resources</a></p>
                                    </li>
                                    <ul class="but-list">
                                        <li>
                                            <p><a href="/resources/how-to-apply-patch/">Patch Application &amp; Rotation Tutorial</a></p>
                                        </li>
                                    </ul>
                                    <li>
                                        <p><a href="/important-safety-information/">Important Safety Information</a></p>
                                    </li>
                                    <li>
                                        <p><a href="/contact-us/">Contact Us</a></p>
                                    </li>
                                    <li>
                                        <p><a href="/terms-of-use/">Terms of Use</a></p>
                                    </li>
                                </ul>

                        </Col>
                    </Row>
                </div>
            </div>
        </Layout>
    );
};

export default SiteMap;
